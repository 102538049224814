<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="ibox">
        <div class="ibox-title">
          <h5>Basic Data Tables example with responsive plugin</h5>
          <div class="ibox-tools">
            <a class="collapse-link">
              <i class="fa fa-chevron-up"></i>
            </a>
            <a class="dropdown-toggle" data-toggle="dropdown" href="#">
              <i class="fa fa-wrench"></i>
            </a>
            <ul class="dropdown-menu dropdown-user">
              <li><a href="#" class="dropdown-item">Config option 1</a></li>
              <li><a href="#" class="dropdown-item">Config option 2</a></li>
            </ul>
            <a class="close-link">
              <i class="fa fa-times"></i>
            </a>
          </div>
        </div>
        <div class="ibox-content">
          <div class="table-responsive">
            <div
              id="DataTables_Table_0_wrapper"
              class="dataTables_wrapper container-fluid dt-bootstrap4"
            >
              <div class="html5buttons">
                <div class="dt-buttons btn-group">
                  <a
                    class="btn btn-default buttons-copy buttons-html5"
                    tabindex="0"
                    aria-controls="DataTables_Table_0"
                    href="#"
                    ><span>Copy</span></a
                  ><a
                    class="btn btn-default buttons-csv buttons-html5"
                    tabindex="0"
                    aria-controls="DataTables_Table_0"
                    href="#"
                    ><span>CSV</span></a
                  ><a
                    class="btn btn-default buttons-excel buttons-html5"
                    tabindex="0"
                    aria-controls="DataTables_Table_0"
                    href="#"
                    ><span>Excel</span></a
                  ><a
                    class="btn btn-default buttons-pdf buttons-html5"
                    tabindex="0"
                    aria-controls="DataTables_Table_0"
                    href="#"
                    ><span>PDF</span></a
                  ><a
                    class="btn btn-default buttons-print"
                    tabindex="0"
                    aria-controls="DataTables_Table_0"
                    href="#"
                    ><span>Print</span></a
                  >
                </div>
              </div>
              <div class="dataTables_length" id="DataTables_Table_0_length">
                <label
                  >Show
                  <select
                    name="DataTables_Table_0_length"
                    aria-controls="DataTables_Table_0"
                    class="form-control form-control-sm"
                  >
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                  entries</label
                >
              </div>
              <div id="DataTables_Table_0_filter" class="dataTables_filter">
                <label
                  >Search:<input
                    type="search"
                    class="form-control form-control-sm"
                    placeholder=""
                    aria-controls="DataTables_Table_0"
                /></label>
              </div>
              <div
                class="dataTables_info"
                id="DataTables_Table_0_info"
                role="status"
                aria-live="polite"
              >
                Showing 1 to 25 of 57 entries
              </div>
              <table
                class="table table-striped table-bordered table-hover dataTables-example dataTable"
                id="DataTables_Table_0"
                aria-describedby="DataTables_Table_0_info"
                role="grid"
              >
                <thead>
                  <tr role="row">
                    <th
                      class="sorting_asc"
                      tabindex="0"
                      aria-controls="DataTables_Table_0"
                      rowspan="1"
                      colspan="1"
                      aria-sort="ascending"
                      aria-label="Rendering engine: activate to sort column descending"
                      style="width: 280px"
                    >
                      Rendering engine
                    </th>
                    <th
                      class="sorting"
                      tabindex="0"
                      aria-controls="DataTables_Table_0"
                      rowspan="1"
                      colspan="1"
                      aria-label="Browser: activate to sort column ascending"
                      style="width: 345px"
                    >
                      Browser
                    </th>
                    <th
                      class="sorting"
                      tabindex="0"
                      aria-controls="DataTables_Table_0"
                      rowspan="1"
                      colspan="1"
                      aria-label="Platform(s): activate to sort column ascending"
                      style="width: 312px"
                    >
                      Platform(s)
                    </th>
                    <th
                      class="sorting"
                      tabindex="0"
                      aria-controls="DataTables_Table_0"
                      rowspan="1"
                      colspan="1"
                      aria-label="Engine version: activate to sort column ascending"
                      style="width: 242px"
                    >
                      Engine version
                    </th>
                    <th
                      class="sorting"
                      tabindex="0"
                      aria-controls="DataTables_Table_0"
                      rowspan="1"
                      colspan="1"
                      aria-label="CSS grade: activate to sort column ascending"
                      style="width: 174px"
                    >
                      CSS grade
                    </th>
                    <th
                      class="sorting"
                      tabindex="0"
                      aria-controls="DataTables_Table_0"
                      rowspan="1"
                      colspan="1"
                      aria-label="CSS grade: activate to sort column ascending"
                      style="width: 174px"
                    >
                      CSS grade
                    </th>
                    <th
                      class="sorting"
                      tabindex="0"
                      aria-controls="DataTables_Table_0"
                      rowspan="1"
                      colspan="1"
                      aria-label="CSS grade: activate to sort column ascending"
                      style="width: 174px"
                    >
                      CSS grade
                    </th>
                    <th
                      class="sorting"
                      tabindex="0"
                      aria-controls="DataTables_Table_0"
                      rowspan="1"
                      colspan="1"
                      aria-label="CSS grade: activate to sort column ascending"
                      style="width: 174px"
                    >
                      CSS grade
                    </th>
                    <th
                      class="sorting"
                      tabindex="0"
                      aria-controls="DataTables_Table_0"
                      rowspan="1"
                      colspan="1"
                      aria-label="CSS grade: activate to sort column ascending"
                      style="width: 174px"
                    >
                      CSS grade
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="gradeA odd"
                    role="row"
                    v-for="(acount, index) in acounts.value"
                    :key="index"
                    @contextmenu.prevent="
                      $refs.menu.open($event, {
                        msal_id: acount.id,
                        email: acount.mail
                      })
                    "
                  >
                    <td>{{ acount.id }}</td>
                    <td>{{ acount.displayName }}</td>
                    <td>{{ acount.givenName }}</td>
                    <td>{{ acount.surname }}</td>
                    <td>{{ acount.jobTitle }}</td>
                    <td>{{ acount.mail }}</td>
                    <td>{{ acount.businessPhones }}</td>
                    <td>{{ acount.mobilePhone }}</td>
                    <td>{{ acount.userPrincipalName }}</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th rowspan="1" colspan="1">Rendering engine</th>
                    <th rowspan="1" colspan="1">Browser</th>
                    <th rowspan="1" colspan="1">Platform(s)</th>
                    <th rowspan="1" colspan="1">Engine version</th>
                    <th rowspan="1" colspan="1">CSS grade</th>
                  </tr>
                </tfoot>
              </table>
              <div
                class="dataTables_paginate paging_simple_numbers"
                id="DataTables_Table_0_paginate"
              >
                <ul class="pagination">
                  <li
                    class="paginate_button page-item previous disabled"
                    id="DataTables_Table_0_previous"
                  >
                    <a
                      href="#"
                      aria-controls="DataTables_Table_0"
                      data-dt-idx="0"
                      tabindex="0"
                      class="page-link"
                      >Previous</a
                    >
                  </li>
                  <li class="paginate_button page-item active">
                    <a
                      href="#"
                      aria-controls="DataTables_Table_0"
                      data-dt-idx="1"
                      tabindex="0"
                      class="page-link"
                      >1</a
                    >
                  </li>
                  <li class="paginate_button page-item">
                    <a
                      href="#"
                      aria-controls="DataTables_Table_0"
                      data-dt-idx="2"
                      tabindex="0"
                      class="page-link"
                      >2</a
                    >
                  </li>
                  <li class="paginate_button page-item">
                    <a
                      href="#"
                      aria-controls="DataTables_Table_0"
                      data-dt-idx="3"
                      tabindex="0"
                      class="page-link"
                      >3</a
                    >
                  </li>
                  <li
                    class="paginate_button page-item next"
                    id="DataTables_Table_0_next"
                  >
                    <a
                      href="#"
                      aria-controls="DataTables_Table_0"
                      data-dt-idx="4"
                      tabindex="0"
                      class="page-link"
                      @click.prevent="get"
                      >Next</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <vue-context ref="menu" v-slot="{ data }">
      <li>
        <a href="#" @click.prevent="post($event.target.innerText, data)">
          Dodaj do nasłuchu
        </a>
      </li>
    </vue-context>
  </div>
</template>

<script>
import MailAdressService from "@/services/mailAdress.service.js";
import MailService from "@/services/mail.service.js";
export default {
  name: "MailAdressList",
  data() {
    return {
      skiptoken: "",
      acounts: {}
    };
  },
  created() {
    this.get();
  },
  methods: {
    get() {
      new MailAdressService()
        .get(this.skiptoken)
        .then(resp => {
          console.log(resp);
          this.acounts = resp.data;
          this.skiptoken = this.acounts["@odata.nextLink"].replace(
            "https://graph.microsoft.com/v1.0/users?$skiptoken=",
            ""
          );
        })
        .catch(err => {
          this.$toastr.error(err);
          console.log(err);
        });
    },
    post($event, data) {
      console.log(data);
      new MailService()
        .post(data)
        .then(resp => {
          this.$toastr.success("Dodano do nasłuchu");
          console.log(resp);
        })
        .catch(err => {
          this.$toastr.error(err);
          console.log(err);
        });
    }
  }
};
</script>
